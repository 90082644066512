.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
  margin:auto;
}

.switch input {
  display: none;
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.slider.disabled {
  background-color: #eee;
  cursor:default;
}

input:checked + .slider {
  background-color:#2196F3;
}

input:checked + .slider.disabled {
  background-color:#A1D6F3;
}

input:focus + .slider {
  box-shadow:0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.card.preference-card {
  width:100%;
}

.preference-header {
  min-height:20px;
  display:flex;
  flex-direction:row;
  font-weight:200;
}

.preference-header.disabled {
  color:#afafaf;
}

.category-header {
  width:80%;
  text-align:left;
}

.selection-header {
  width:20%;
  min-width:
  71px;
  text-align:center;
}

.separator {
  width:100%;
  height:1px;
  background:lightgrey;
}

.preference-card-option {
  width:100%;
  min-height:20px;
  display:flex;
  flex-direction:row;
  align-items:center;
  -webkit-transition:.4s;
  transition:.4s;
}

.option-detail {
  width:10%;
  min-height:20px;
  display:flex;
  flex-direction:column;
  text-align:left;
  -webkit-transition:.4s;
  transition:.4s;
}

.option-header {
  margin:0px;
  margin-top:16px;
  font-size:15px;
  -webkit-transition:.4s;
  transition:.4s;
}

.option-header.disabled {
  color:#afafaf;
  -webkit-transition:.4s;
  transition:.4s;
}

.option-header.disabled:before {
  color:#afafaf;
  -webkit-transition:.4s;
  transition:.4s;
}

.checkbox.disabled {
  color:#afafaf !important;
  -webkit-transition:.4s;
  background-color: #afafaf;
  transition:.4s;
}

.option-description {
  -webkit-transition:.4s;
  transition:.4s;
}

.option-description.disabled {
  color:#afafaf;
  -webkit-transition:.4s;
  transition:.4s;
}

.option-toggle {
  width:20%;
  min-width:71px;
  min-height:40px;
}

.email-title {
  font-size:25px;
  overflow-wrap:anywhere;
}

.sms-title {
  font-size:32px;
  direction: ltr;
}

.opt-status {
  font-size:32px;
  color:#c84b3f;
  font-weight:600;
}

.greet-status {
  font-size:32px;
  font-weight:600;
}

.opt-sub {
  font-size:15px;
  margin-bottom:64px;
}


.container.main-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  max-width:800px;
  margin-top:40px;
  margin-bottom:40px;
}

.save-pref-btn {
  min-width:260px;
  margin-top:18px;
  background-color:#007bff;
  color:#007bff;
  -webkit-transition:.4s;
}

.save-pref-btn.SAVED {
  min-width:260px;
  margin-top:18px;
  background-color:#00ce08 !important;
  border-color:#00ce08 !important;
  -webkit-transition:.1s;
}

.save-pref-btn.ERROR {
  min-width:260px;
  margin-top:18px;
  background-color:#ff1a00 !important;
  border-color:#ff1a00 !important;
  -webkit-transition:.1s;
}

.conf-dialog {
  font-family: Graphik,Helvetica Neue,Arial,Helvetica,sans-serif;
}

.conf-disclaimer {
  font-style: italic;
  font-size: 14px;
}

.conf-footer {
  display: flex;
  flex-direction: column;
}

.conf-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.conf-contact-root {
  margin-top: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
}

.conf-contact-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conf-contact-address {
  display: flex;
  flex-direction: column;
}

.conf-contact-root p {
  margin-bottom: .5rem;
}

.conf-affirm-btn {
  background-color: #fffc00;
  color: #262626;
  margin-left: .5rem;
  -webkit-transition:.4s;
}
